<template>
  <div class="tixianresult">
    <van-nav-bar
      title="提现结果"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="tixian_main">
      <van-icon name="checked"></van-icon>
      <div class="tixian_res_title">
        提现申请成功
      </div>
      <div class="tixian_res_content">
        您的提现申请已提交给大咖，等待客服审核，审核通过后5个工作日内到您绑定微信余额中。
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TixianResult",
  data() {
    return {
      shareid: 0,
      merchid: 0
    }
  },
  mounted() {
    
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;

    this.$parent.getmerchname(this.merchid, "提现结果");
  },
  methods: {
    
    // 返回上一页
    onClickLeft(){
      this.$router.go(-1);
    }
  }
}
</script>
<style lang="less">
.tixianresult {
  width: 100%;
  height: 100%;
  background-color: #ededed;
  overflow: hidden;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .tixian_main {
    padding: 16px;
    margin: 16px;
    border-radius: 10px;
    background-color: #fff;
    .van-icon {
      font-size: 60px;
      color: #5DCB71;
    }
    .tixian_res_title {
      font-size: 16px;
      font-weight: bold;
      color: #333;
      line-height: 24px;
      margin: 10px 0 20px;
    }
    .tixian_res_content{
      font-size: 14px;
      line-height: 24px;
      color: #333;
    }
  }
}
</style>